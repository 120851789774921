@use '@/styles/utils/mixins.scss' as *;

.stickyWrapper {
  position: relative;
  margin-bottom: 15.25rem;

  @include tab() {
    margin-bottom: 12.5rem;
  }
}

.offeringWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.75rem;

  @include tab() {
    grid-template-columns: repeat(1, 1fr);
  }
  .navContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;

    @include tab() {
      flex-direction: column;
    }
  }
  .offeringContentContainer {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
    position: sticky;
    top: 0;
    align-self: start;

    @include tab() {
      gap: 3.75rem;
      overflow-x: auto;
      width: 100%;
    }
  }

  .tabNavigation {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    margin-top: 4rem;

    @include tab() {
      flex-direction: row;
      gap: 1.25rem;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      margin-top: 0rem;
    }

    .tabButton {
      display: flex;
      align-items: baseline;
      font-size: 1.5rem;
      gap: 0.25rem;
      color: $neutral07;
      border: none;
      background: none;
      cursor: pointer;
      text-align: left;
      width: max-content;
      position: relative;
      font-weight: 500;

      h3 {
        font-weight: normal;
        font-size: inherit;
      }

      @include tab() {
        font-size: 1rem;
        text-wrap: nowrap;
        font-weight: 400;
      }

      .productType {
        font-size: 0.875rem;

        @include tab() {
          font-size: 1rem;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0.063rem;
        width: 0;
        background-color: $primary;
        transition: width 0.3s;
      }

      &:hover::before,
      &:focus::before,
      &:active::before,
      &.active::before {
        width: 100%;
        transition: width 0.3s;
      }

      &:active,
      &:focus,
      &:hover,
      &.active {
        color: $primary;
      }

      &.active {
        @include tab() {
          font-weight: bold;
        }
      }
    }
  }

  .offeringContentDetails {
    width: 100%;
  }

  .offeringCategoryHeading {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    color: $primary;
    gap: 1.25rem;
    width: 100%;

    h4 {
      font-weight: normal;
    }

    &::after {
      content: '';

      flex: 1 1 auto;
      height: 0.063rem;
      background-color: $primaryAlt;
    }

    @include tab {
      font-size: 1rem;
    }
  }

  .offeringList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem;

    padding-top: 12.5rem;
    flex: 1 1 auto;
    height: 100%;
    max-height: calc(100vh - 7.5rem);
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;

    @include tab() {
      height: auto;
      overflow-y: visible;
      overflow-x: hidden;
      max-height: 100%;
      padding-top: 0rem;
    }
  }

  .offeringCategory {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .offeringCard {
    border-radius: 1.25rem;
    padding: 2.5rem 1.875rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    background-color: $white;
    border: 0 solid $white;
    -webkit-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    -moz-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
    transition: all 0.2s ease-in-out;

    .offeringCardBody {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }

    .description {
      color: $neutral08;
    }
  }
}
